import React from 'react'
import Header from '../Header/Header'
import './Hero.css'

const Hero = () => {
  return (
    <div className='hero' id='home'>
      <Header />
      <div className='hero_text'>
        <h4 className='name'>Harsh Varshney</h4>
        <h1 className='tl'></h1>
        <svg id='logo' width="1037" height="143" viewBox="0 0 1037 143" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="path-1-outside-1_18_2" maskUnits="userSpaceOnUse" x="0.174988" y="0.624969" width="1037" height="142" fill="black">
            <rect fill="white" x="0.174988" y="0.624969" width="1037" height="142" />
            <path d="M2.17499 8.44414H8.01999L34.0442 96.1192L60.0683 8.44414H65.9133L91.9375 96.1192L117.962 8.44414H123.807L94.86 106H89.015L62.9908 18.325L36.9667 106H31.1217L2.17499 8.44414Z" />
            <path d="M200.111 91.2483C195.658 97.1861 190.834 101.408 185.638 103.913C180.442 106.325 174.319 107.531 167.268 107.531C162.258 107.531 157.434 106.603 152.795 104.748C148.249 102.799 144.259 100.155 140.826 96.815C137.394 93.475 134.657 89.5783 132.616 85.125C130.574 80.6717 129.554 75.94 129.554 70.93C129.554 65.92 130.482 61.1883 132.337 56.735C134.286 52.2817 136.93 48.385 140.27 45.045C143.61 41.705 147.506 39.1072 151.96 37.2517C156.413 35.3033 161.145 34.3291 166.155 34.3291C171.165 34.3291 175.85 35.2569 180.211 37.1125C184.571 38.8753 188.329 41.3803 191.483 44.6275C194.73 47.7819 197.282 51.5394 199.137 55.9C200.993 60.2605 201.921 64.9458 201.921 69.9558V72.7392H134.981C135.26 76.8214 136.28 80.6717 138.043 84.29C139.899 87.8156 142.311 90.9236 145.28 93.6142C148.249 96.212 151.589 98.2994 155.3 99.8767C159.104 101.361 163.093 102.103 167.268 102.103C170.33 102.103 173.159 101.871 175.757 101.408C178.448 100.944 180.953 100.155 183.272 99.0417C185.592 97.9283 187.772 96.4903 189.813 94.7275C191.947 92.8719 194.034 90.5989 196.076 87.9083L200.111 91.2483ZM196.076 67.7292C196.076 63.9253 195.241 60.3533 193.571 57.0133C191.993 53.6733 189.813 50.7508 187.03 48.2458C184.339 45.648 181.185 43.6069 177.566 42.1225C173.948 40.638 170.144 39.8958 166.155 39.8958C162.165 39.8958 158.361 40.638 154.743 42.1225C151.217 43.5142 148.063 45.4625 145.28 47.9675C142.496 50.4725 140.223 53.4414 138.461 56.8742C136.698 60.2142 135.584 63.8325 135.12 67.7292H196.076Z" />
            <path d="M218.941 1.62497H224.508V52.0033C227.755 46.715 232.069 42.54 237.45 39.4783C242.924 36.3239 248.955 34.7467 255.542 34.7467C260.552 34.7467 265.284 35.7208 269.737 37.6691C274.19 39.5247 278.087 42.1225 281.427 45.4625C284.767 48.8025 287.365 52.6992 289.22 57.1525C291.169 61.6058 292.143 66.3375 292.143 71.3475C292.143 76.3575 291.169 81.0892 289.22 85.5425C287.365 89.9958 284.767 93.8925 281.427 97.2325C278.087 100.573 274.19 103.217 269.737 105.165C265.284 107.021 260.552 107.948 255.542 107.948C248.955 107.948 242.924 106.418 237.45 103.356C232.069 100.201 227.755 95.98 224.508 90.6917V106H218.941V1.62497ZM224.508 74.1308C224.879 78.0275 225.946 81.6922 227.709 85.125C229.472 88.5578 231.745 91.5731 234.528 94.1708C237.311 96.6758 240.512 98.6706 244.13 100.155C247.749 101.639 251.553 102.382 255.542 102.382C259.81 102.382 263.846 101.593 267.65 100.016C271.454 98.3458 274.747 96.1192 277.53 93.3358C280.407 90.4597 282.633 87.1197 284.21 83.3158C285.88 79.5119 286.715 75.4761 286.715 71.2083C286.715 66.9406 285.88 62.9511 284.21 59.24C282.633 55.4361 280.407 52.1425 277.53 49.3592C274.747 46.483 271.454 44.2564 267.65 42.6792C263.846 41.0092 259.81 40.1742 255.542 40.1742C251.553 40.1742 247.749 40.9164 244.13 42.4008C240.512 43.8853 237.311 45.88 234.528 48.385C231.745 50.89 229.472 53.8589 227.709 57.2917C225.946 60.7244 224.879 64.3892 224.508 68.2858V74.1308Z" />
            <path d="M346.485 7.88747H378.076C384.849 7.88747 391.204 9.18636 397.142 11.7841C403.08 14.2891 408.275 17.7683 412.729 22.2216C417.182 26.675 420.661 31.8705 423.166 37.8083C425.764 43.7461 427.063 50.1014 427.063 56.8742C427.063 63.6469 425.764 70.0486 423.166 76.0792C420.661 82.0169 417.182 87.2125 412.729 91.6658C408.275 96.1192 403.08 99.6447 397.142 102.243C391.204 104.748 384.849 106 378.076 106H346.485V7.88747ZM378.076 100.294C384.107 100.294 389.72 99.1808 394.915 96.9542C400.204 94.6347 404.796 91.5267 408.693 87.63C412.682 83.6406 415.79 79.0017 418.017 73.7133C420.337 68.425 421.496 62.8119 421.496 56.8742C421.496 50.8436 420.337 45.2305 418.017 40.035C415.79 34.7466 412.682 30.1541 408.693 26.2575C404.796 22.268 400.204 19.16 394.915 16.9333C389.72 14.6139 384.107 13.4541 378.076 13.4541H352.052V100.433H378.076V100.294Z" />
            <path d="M511.742 91.2483C507.288 97.1861 502.464 101.408 497.268 103.913C492.073 106.325 485.949 107.531 478.898 107.531C473.888 107.531 469.064 106.603 464.425 104.748C459.879 102.799 455.889 100.155 452.456 96.815C449.024 93.475 446.287 89.5783 444.246 85.125C442.205 80.6717 441.184 75.94 441.184 70.93C441.184 65.92 442.112 61.1883 443.967 56.735C445.916 52.2817 448.56 48.385 451.9 45.045C455.24 41.705 459.136 39.1072 463.59 37.2517C468.043 35.3033 472.775 34.3291 477.785 34.3291C482.795 34.3291 487.48 35.2569 491.841 37.1125C496.201 38.8753 499.959 41.3803 503.113 44.6275C506.36 47.7819 508.912 51.5394 510.767 55.9C512.623 60.2605 513.551 64.9458 513.551 69.9558V72.7392H446.611C446.89 76.8214 447.91 80.6717 449.673 84.29C451.529 87.8156 453.941 90.9236 456.91 93.6142C459.879 96.212 463.219 98.2994 466.93 99.8767C470.734 101.361 474.723 102.103 478.898 102.103C481.96 102.103 484.79 101.871 487.387 101.408C490.078 100.944 492.583 100.155 494.902 99.0417C497.222 97.9283 499.402 96.4903 501.443 94.7275C503.577 92.8719 505.665 90.5989 507.706 87.9083L511.742 91.2483ZM507.706 67.7292C507.706 63.9253 506.871 60.3533 505.201 57.0133C503.623 53.6733 501.443 50.7508 498.66 48.2458C495.969 45.648 492.815 43.6069 489.197 42.1225C485.578 40.638 481.774 39.8958 477.785 39.8958C473.795 39.8958 469.992 40.638 466.373 42.1225C462.848 43.5142 459.693 45.4625 456.91 47.9675C454.126 50.4725 451.853 53.4414 450.091 56.8742C448.328 60.2142 447.215 63.8325 446.751 67.7292H507.706Z" />
            <path d="M580.132 36.4167H586.255L555.5 106H549.376L518.62 36.4167H524.744L552.438 99.0417L580.132 36.4167Z" />
            <path d="M664.363 91.2483C659.909 97.1861 655.085 101.408 649.889 103.913C644.694 106.325 638.571 107.531 631.519 107.531C626.509 107.531 621.685 106.603 617.046 104.748C612.5 102.799 608.511 100.155 605.078 96.815C601.645 93.475 598.908 89.5783 596.867 85.125C594.826 80.6717 593.805 75.94 593.805 70.93C593.805 65.92 594.733 61.1883 596.589 56.735C598.537 52.2817 601.181 48.385 604.521 45.045C607.861 41.705 611.758 39.1072 616.211 37.2517C620.664 35.3033 625.396 34.3291 630.406 34.3291C635.416 34.3291 640.101 35.2569 644.462 37.1125C648.823 38.8753 652.58 41.3803 655.734 44.6275C658.982 47.7819 661.533 51.5394 663.389 55.9C665.244 60.2605 666.172 64.9458 666.172 69.9558V72.7392H599.233C599.511 76.8214 600.532 80.6717 602.294 84.29C604.15 87.8156 606.562 90.9236 609.531 93.6142C612.5 96.212 615.84 98.2994 619.551 99.8767C623.355 101.361 627.344 102.103 631.519 102.103C634.581 102.103 637.411 101.871 640.009 101.408C642.699 100.944 645.204 100.155 647.524 99.0417C649.843 97.9283 652.023 96.4903 654.064 94.7275C656.198 92.8719 658.286 90.5989 660.327 87.9083L664.363 91.2483ZM660.327 67.7292C660.327 63.9253 659.492 60.3533 657.822 57.0133C656.245 53.6733 654.064 50.7508 651.281 48.2458C648.591 45.648 645.436 43.6069 641.818 42.1225C638.199 40.638 634.396 39.8958 630.406 39.8958C626.417 39.8958 622.613 40.638 618.994 42.1225C615.469 43.5142 612.314 45.4625 609.531 47.9675C606.748 50.4725 604.475 53.4414 602.712 56.8742C600.949 60.2142 599.836 63.8325 599.372 67.7292H660.327Z" />
            <path d="M682.22 1.62497H687.786V106H682.22V1.62497Z" />
            <path d="M704.704 70.93C704.704 65.92 705.632 61.1883 707.487 56.735C709.435 52.2817 712.08 48.385 715.42 45.045C718.76 41.705 722.656 39.1072 727.11 37.2517C731.563 35.3033 736.295 34.3291 741.305 34.3291C746.315 34.3291 751.046 35.3033 755.5 37.2517C759.953 39.1072 763.85 41.705 767.19 45.045C770.53 48.385 773.127 52.2817 774.983 56.735C776.931 61.1883 777.905 65.92 777.905 70.93C777.905 75.94 776.931 80.6717 774.983 85.125C773.127 89.5783 770.53 93.475 767.19 96.815C763.85 100.155 759.953 102.799 755.5 104.748C751.046 106.603 746.315 107.531 741.305 107.531C736.295 107.531 731.563 106.603 727.11 104.748C722.656 102.799 718.76 100.155 715.42 96.815C712.08 93.475 709.435 89.5783 707.487 85.125C705.632 80.6717 704.704 75.94 704.704 70.93ZM710.131 71.0692C710.131 75.3369 710.92 79.3728 712.497 83.1767C714.167 86.8878 716.394 90.1814 719.177 93.0575C722.053 95.8408 725.393 98.0675 729.197 99.7375C733.001 101.315 737.037 102.103 741.305 102.103C745.572 102.103 749.608 101.315 753.412 99.7375C757.216 98.0675 760.51 95.8408 763.293 93.0575C766.169 90.1814 768.396 86.8878 769.973 83.1767C771.643 79.3728 772.478 75.3369 772.478 71.0692C772.478 66.8014 771.643 62.7655 769.973 58.9617C768.396 55.1578 766.169 51.8642 763.293 49.0808C760.51 46.2047 757.216 43.978 753.412 42.4008C749.608 40.7308 745.572 39.8958 741.305 39.8958C737.037 39.8958 733.001 40.7308 729.197 42.4008C725.393 43.978 722.053 46.2047 719.177 49.0808C716.394 51.8642 714.167 55.1578 712.497 58.9617C710.92 62.7655 710.131 66.8014 710.131 71.0692Z" />
            <path d="M787.088 36.4167H799.752V52.0033C803 46.715 807.314 42.54 812.695 39.4783C818.169 36.3239 824.199 34.7467 830.787 34.7467C835.797 34.7467 840.528 35.7208 844.982 37.6691C849.435 39.5247 853.332 42.1225 856.672 45.4625C860.012 48.8025 862.609 52.6992 864.465 57.1525C866.413 61.6058 867.387 66.3375 867.387 71.3475C867.387 76.3575 866.413 81.0892 864.465 85.5425C862.609 89.9958 860.012 93.8925 856.672 97.2325C853.332 100.573 849.435 103.217 844.982 105.165C840.528 107.021 835.797 107.948 830.787 107.948C824.199 107.948 818.169 106.418 812.695 103.356C807.314 100.201 803 95.98 799.752 90.6917V141.07H794.186V40.3133H787.088V36.4167ZM799.752 74.4092C800.124 78.3058 801.19 81.9706 802.953 85.4033C804.716 88.8361 806.989 91.805 809.772 94.31C812.556 96.815 815.757 98.8097 819.375 100.294C822.993 101.779 826.797 102.521 830.787 102.521C835.054 102.521 839.09 101.732 842.894 100.155C846.698 98.485 849.992 96.2583 852.775 93.475C855.651 90.5989 857.878 87.3053 859.455 83.5942C861.125 79.7903 861.96 75.7544 861.96 71.4867C861.96 67.2189 861.125 63.183 859.455 59.3792C857.878 55.5753 855.651 52.2817 852.775 49.4983C849.992 46.6222 846.698 44.3955 842.894 42.8183C839.09 41.1483 835.054 40.3133 830.787 40.3133C826.797 40.3133 822.993 41.0555 819.375 42.54C815.757 44.0244 812.556 46.0655 809.772 48.6633C806.989 51.1683 804.716 54.1372 802.953 57.57C801.19 61.0028 800.124 64.6675 799.752 68.5642V74.4092Z" />
            <path d="M951.258 91.2483C946.805 97.1861 941.98 101.408 936.785 103.913C931.589 106.325 925.466 107.531 918.415 107.531C913.405 107.531 908.58 106.603 903.942 104.748C899.395 102.799 895.406 100.155 891.973 96.815C888.54 93.475 885.804 89.5783 883.762 85.125C881.721 80.6717 880.701 75.94 880.701 70.93C880.701 65.92 881.629 61.1883 883.484 56.735C885.432 52.2817 888.077 48.385 891.417 45.045C894.757 41.705 898.653 39.1072 903.107 37.2517C907.56 35.3033 912.292 34.3291 917.302 34.3291C922.312 34.3291 926.997 35.2569 931.357 37.1125C935.718 38.8753 939.475 41.3803 942.63 44.6275C945.877 47.7819 948.429 51.5394 950.284 55.9C952.14 60.2605 953.067 64.9458 953.067 69.9558V72.7392H886.128C886.407 76.8214 887.427 80.6717 889.19 84.29C891.045 87.8156 893.458 90.9236 896.427 93.6142C899.395 96.212 902.735 98.2994 906.447 99.8767C910.25 101.361 914.24 102.103 918.415 102.103C921.477 102.103 924.306 101.871 926.904 101.408C929.595 100.944 932.1 100.155 934.419 99.0417C936.739 97.9283 938.919 96.4903 940.96 94.7275C943.094 92.8719 945.181 90.5989 947.222 87.9083L951.258 91.2483ZM947.222 67.7292C947.222 63.9253 946.387 60.3533 944.717 57.0133C943.14 53.6733 940.96 50.7508 938.177 48.2458C935.486 45.648 932.332 43.6069 928.713 42.1225C925.095 40.638 921.291 39.8958 917.302 39.8958C913.312 39.8958 909.508 40.638 905.89 42.1225C902.364 43.5142 899.21 45.4625 896.427 47.9675C893.643 50.4725 891.37 53.4414 889.607 56.8742C887.845 60.2142 886.731 63.8325 886.267 67.7292H947.222Z" />
            <path d="M965.283 36.4167H977.947V51.8642C981.194 46.5758 985.508 42.4008 990.889 39.3391C996.363 36.1847 1002.39 34.6075 1008.98 34.6075C1014.18 34.6075 1019.05 35.628 1023.59 37.6691C1028.14 39.6175 1032.08 42.3544 1035.42 45.88L1031.39 49.7767C1028.51 46.8078 1025.17 44.4883 1021.37 42.8183C1017.56 41.0555 1013.43 40.1742 1008.98 40.1742C1004.71 40.1742 1000.68 41.0092 996.873 42.6792C993.162 44.2564 989.869 46.483 986.993 49.3592C984.209 52.1425 981.983 55.4361 980.313 59.24C978.735 62.9511 977.947 66.9406 977.947 71.2083V106H972.38V40.3133H965.283V36.4167Z" />
          </mask>
          <path d="M2.17499 8.44414H8.01999L34.0442 96.1192L60.0683 8.44414H65.9133L91.9375 96.1192L117.962 8.44414H123.807L94.86 106H89.015L62.9908 18.325L36.9667 106H31.1217L2.17499 8.44414Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M200.111 91.2483C195.658 97.1861 190.834 101.408 185.638 103.913C180.442 106.325 174.319 107.531 167.268 107.531C162.258 107.531 157.434 106.603 152.795 104.748C148.249 102.799 144.259 100.155 140.826 96.815C137.394 93.475 134.657 89.5783 132.616 85.125C130.574 80.6717 129.554 75.94 129.554 70.93C129.554 65.92 130.482 61.1883 132.337 56.735C134.286 52.2817 136.93 48.385 140.27 45.045C143.61 41.705 147.506 39.1072 151.96 37.2517C156.413 35.3033 161.145 34.3291 166.155 34.3291C171.165 34.3291 175.85 35.2569 180.211 37.1125C184.571 38.8753 188.329 41.3803 191.483 44.6275C194.73 47.7819 197.282 51.5394 199.137 55.9C200.993 60.2605 201.921 64.9458 201.921 69.9558V72.7392H134.981C135.26 76.8214 136.28 80.6717 138.043 84.29C139.899 87.8156 142.311 90.9236 145.28 93.6142C148.249 96.212 151.589 98.2994 155.3 99.8767C159.104 101.361 163.093 102.103 167.268 102.103C170.33 102.103 173.159 101.871 175.757 101.408C178.448 100.944 180.953 100.155 183.272 99.0417C185.592 97.9283 187.772 96.4903 189.813 94.7275C191.947 92.8719 194.034 90.5989 196.076 87.9083L200.111 91.2483ZM196.076 67.7292C196.076 63.9253 195.241 60.3533 193.571 57.0133C191.993 53.6733 189.813 50.7508 187.03 48.2458C184.339 45.648 181.185 43.6069 177.566 42.1225C173.948 40.638 170.144 39.8958 166.155 39.8958C162.165 39.8958 158.361 40.638 154.743 42.1225C151.217 43.5142 148.063 45.4625 145.28 47.9675C142.496 50.4725 140.223 53.4414 138.461 56.8742C136.698 60.2142 135.584 63.8325 135.12 67.7292H196.076Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M218.941 1.62497H224.508V52.0033C227.755 46.715 232.069 42.54 237.45 39.4783C242.924 36.3239 248.955 34.7467 255.542 34.7467C260.552 34.7467 265.284 35.7208 269.737 37.6691C274.19 39.5247 278.087 42.1225 281.427 45.4625C284.767 48.8025 287.365 52.6992 289.22 57.1525C291.169 61.6058 292.143 66.3375 292.143 71.3475C292.143 76.3575 291.169 81.0892 289.22 85.5425C287.365 89.9958 284.767 93.8925 281.427 97.2325C278.087 100.573 274.19 103.217 269.737 105.165C265.284 107.021 260.552 107.948 255.542 107.948C248.955 107.948 242.924 106.418 237.45 103.356C232.069 100.201 227.755 95.98 224.508 90.6917V106H218.941V1.62497ZM224.508 74.1308C224.879 78.0275 225.946 81.6922 227.709 85.125C229.472 88.5578 231.745 91.5731 234.528 94.1708C237.311 96.6758 240.512 98.6706 244.13 100.155C247.749 101.639 251.553 102.382 255.542 102.382C259.81 102.382 263.846 101.593 267.65 100.016C271.454 98.3458 274.747 96.1192 277.53 93.3358C280.407 90.4597 282.633 87.1197 284.21 83.3158C285.88 79.5119 286.715 75.4761 286.715 71.2083C286.715 66.9406 285.88 62.9511 284.21 59.24C282.633 55.4361 280.407 52.1425 277.53 49.3592C274.747 46.483 271.454 44.2564 267.65 42.6792C263.846 41.0092 259.81 40.1742 255.542 40.1742C251.553 40.1742 247.749 40.9164 244.13 42.4008C240.512 43.8853 237.311 45.88 234.528 48.385C231.745 50.89 229.472 53.8589 227.709 57.2917C225.946 60.7244 224.879 64.3892 224.508 68.2858V74.1308Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M346.485 7.88747H378.076C384.849 7.88747 391.204 9.18636 397.142 11.7841C403.08 14.2891 408.275 17.7683 412.729 22.2216C417.182 26.675 420.661 31.8705 423.166 37.8083C425.764 43.7461 427.063 50.1014 427.063 56.8742C427.063 63.6469 425.764 70.0486 423.166 76.0792C420.661 82.0169 417.182 87.2125 412.729 91.6658C408.275 96.1192 403.08 99.6447 397.142 102.243C391.204 104.748 384.849 106 378.076 106H346.485V7.88747ZM378.076 100.294C384.107 100.294 389.72 99.1808 394.915 96.9542C400.204 94.6347 404.796 91.5267 408.693 87.63C412.682 83.6406 415.79 79.0017 418.017 73.7133C420.337 68.425 421.496 62.8119 421.496 56.8742C421.496 50.8436 420.337 45.2305 418.017 40.035C415.79 34.7466 412.682 30.1541 408.693 26.2575C404.796 22.268 400.204 19.16 394.915 16.9333C389.72 14.6139 384.107 13.4541 378.076 13.4541H352.052V100.433H378.076V100.294Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M511.742 91.2483C507.288 97.1861 502.464 101.408 497.268 103.913C492.073 106.325 485.949 107.531 478.898 107.531C473.888 107.531 469.064 106.603 464.425 104.748C459.879 102.799 455.889 100.155 452.456 96.815C449.024 93.475 446.287 89.5783 444.246 85.125C442.205 80.6717 441.184 75.94 441.184 70.93C441.184 65.92 442.112 61.1883 443.967 56.735C445.916 52.2817 448.56 48.385 451.9 45.045C455.24 41.705 459.136 39.1072 463.59 37.2517C468.043 35.3033 472.775 34.3291 477.785 34.3291C482.795 34.3291 487.48 35.2569 491.841 37.1125C496.201 38.8753 499.959 41.3803 503.113 44.6275C506.36 47.7819 508.912 51.5394 510.767 55.9C512.623 60.2605 513.551 64.9458 513.551 69.9558V72.7392H446.611C446.89 76.8214 447.91 80.6717 449.673 84.29C451.529 87.8156 453.941 90.9236 456.91 93.6142C459.879 96.212 463.219 98.2994 466.93 99.8767C470.734 101.361 474.723 102.103 478.898 102.103C481.96 102.103 484.79 101.871 487.387 101.408C490.078 100.944 492.583 100.155 494.902 99.0417C497.222 97.9283 499.402 96.4903 501.443 94.7275C503.577 92.8719 505.665 90.5989 507.706 87.9083L511.742 91.2483ZM507.706 67.7292C507.706 63.9253 506.871 60.3533 505.201 57.0133C503.623 53.6733 501.443 50.7508 498.66 48.2458C495.969 45.648 492.815 43.6069 489.197 42.1225C485.578 40.638 481.774 39.8958 477.785 39.8958C473.795 39.8958 469.992 40.638 466.373 42.1225C462.848 43.5142 459.693 45.4625 456.91 47.9675C454.126 50.4725 451.853 53.4414 450.091 56.8742C448.328 60.2142 447.215 63.8325 446.751 67.7292H507.706Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M580.132 36.4167H586.255L555.5 106H549.376L518.62 36.4167H524.744L552.438 99.0417L580.132 36.4167Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M664.363 91.2483C659.909 97.1861 655.085 101.408 649.889 103.913C644.694 106.325 638.571 107.531 631.519 107.531C626.509 107.531 621.685 106.603 617.046 104.748C612.5 102.799 608.511 100.155 605.078 96.815C601.645 93.475 598.908 89.5783 596.867 85.125C594.826 80.6717 593.805 75.94 593.805 70.93C593.805 65.92 594.733 61.1883 596.589 56.735C598.537 52.2817 601.181 48.385 604.521 45.045C607.861 41.705 611.758 39.1072 616.211 37.2517C620.664 35.3033 625.396 34.3291 630.406 34.3291C635.416 34.3291 640.101 35.2569 644.462 37.1125C648.823 38.8753 652.58 41.3803 655.734 44.6275C658.982 47.7819 661.533 51.5394 663.389 55.9C665.244 60.2605 666.172 64.9458 666.172 69.9558V72.7392H599.233C599.511 76.8214 600.532 80.6717 602.294 84.29C604.15 87.8156 606.562 90.9236 609.531 93.6142C612.5 96.212 615.84 98.2994 619.551 99.8767C623.355 101.361 627.344 102.103 631.519 102.103C634.581 102.103 637.411 101.871 640.009 101.408C642.699 100.944 645.204 100.155 647.524 99.0417C649.843 97.9283 652.023 96.4903 654.064 94.7275C656.198 92.8719 658.286 90.5989 660.327 87.9083L664.363 91.2483ZM660.327 67.7292C660.327 63.9253 659.492 60.3533 657.822 57.0133C656.245 53.6733 654.064 50.7508 651.281 48.2458C648.591 45.648 645.436 43.6069 641.818 42.1225C638.199 40.638 634.396 39.8958 630.406 39.8958C626.417 39.8958 622.613 40.638 618.994 42.1225C615.469 43.5142 612.314 45.4625 609.531 47.9675C606.748 50.4725 604.475 53.4414 602.712 56.8742C600.949 60.2142 599.836 63.8325 599.372 67.7292H660.327Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M682.22 1.62497H687.786V106H682.22V1.62497Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M704.704 70.93C704.704 65.92 705.632 61.1883 707.487 56.735C709.435 52.2817 712.08 48.385 715.42 45.045C718.76 41.705 722.656 39.1072 727.11 37.2517C731.563 35.3033 736.295 34.3291 741.305 34.3291C746.315 34.3291 751.046 35.3033 755.5 37.2517C759.953 39.1072 763.85 41.705 767.19 45.045C770.53 48.385 773.127 52.2817 774.983 56.735C776.931 61.1883 777.905 65.92 777.905 70.93C777.905 75.94 776.931 80.6717 774.983 85.125C773.127 89.5783 770.53 93.475 767.19 96.815C763.85 100.155 759.953 102.799 755.5 104.748C751.046 106.603 746.315 107.531 741.305 107.531C736.295 107.531 731.563 106.603 727.11 104.748C722.656 102.799 718.76 100.155 715.42 96.815C712.08 93.475 709.435 89.5783 707.487 85.125C705.632 80.6717 704.704 75.94 704.704 70.93ZM710.131 71.0692C710.131 75.3369 710.92 79.3728 712.497 83.1767C714.167 86.8878 716.394 90.1814 719.177 93.0575C722.053 95.8408 725.393 98.0675 729.197 99.7375C733.001 101.315 737.037 102.103 741.305 102.103C745.572 102.103 749.608 101.315 753.412 99.7375C757.216 98.0675 760.51 95.8408 763.293 93.0575C766.169 90.1814 768.396 86.8878 769.973 83.1767C771.643 79.3728 772.478 75.3369 772.478 71.0692C772.478 66.8014 771.643 62.7655 769.973 58.9617C768.396 55.1578 766.169 51.8642 763.293 49.0808C760.51 46.2047 757.216 43.978 753.412 42.4008C749.608 40.7308 745.572 39.8958 741.305 39.8958C737.037 39.8958 733.001 40.7308 729.197 42.4008C725.393 43.978 722.053 46.2047 719.177 49.0808C716.394 51.8642 714.167 55.1578 712.497 58.9617C710.92 62.7655 710.131 66.8014 710.131 71.0692Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M787.088 36.4167H799.752V52.0033C803 46.715 807.314 42.54 812.695 39.4783C818.169 36.3239 824.199 34.7467 830.787 34.7467C835.797 34.7467 840.528 35.7208 844.982 37.6691C849.435 39.5247 853.332 42.1225 856.672 45.4625C860.012 48.8025 862.609 52.6992 864.465 57.1525C866.413 61.6058 867.387 66.3375 867.387 71.3475C867.387 76.3575 866.413 81.0892 864.465 85.5425C862.609 89.9958 860.012 93.8925 856.672 97.2325C853.332 100.573 849.435 103.217 844.982 105.165C840.528 107.021 835.797 107.948 830.787 107.948C824.199 107.948 818.169 106.418 812.695 103.356C807.314 100.201 803 95.98 799.752 90.6917V141.07H794.186V40.3133H787.088V36.4167ZM799.752 74.4092C800.124 78.3058 801.19 81.9706 802.953 85.4033C804.716 88.8361 806.989 91.805 809.772 94.31C812.556 96.815 815.757 98.8097 819.375 100.294C822.993 101.779 826.797 102.521 830.787 102.521C835.054 102.521 839.09 101.732 842.894 100.155C846.698 98.485 849.992 96.2583 852.775 93.475C855.651 90.5989 857.878 87.3053 859.455 83.5942C861.125 79.7903 861.96 75.7544 861.96 71.4867C861.96 67.2189 861.125 63.183 859.455 59.3792C857.878 55.5753 855.651 52.2817 852.775 49.4983C849.992 46.6222 846.698 44.3955 842.894 42.8183C839.09 41.1483 835.054 40.3133 830.787 40.3133C826.797 40.3133 822.993 41.0555 819.375 42.54C815.757 44.0244 812.556 46.0655 809.772 48.6633C806.989 51.1683 804.716 54.1372 802.953 57.57C801.19 61.0028 800.124 64.6675 799.752 68.5642V74.4092Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M951.258 91.2483C946.805 97.1861 941.98 101.408 936.785 103.913C931.589 106.325 925.466 107.531 918.415 107.531C913.405 107.531 908.58 106.603 903.942 104.748C899.395 102.799 895.406 100.155 891.973 96.815C888.54 93.475 885.804 89.5783 883.762 85.125C881.721 80.6717 880.701 75.94 880.701 70.93C880.701 65.92 881.629 61.1883 883.484 56.735C885.432 52.2817 888.077 48.385 891.417 45.045C894.757 41.705 898.653 39.1072 903.107 37.2517C907.56 35.3033 912.292 34.3291 917.302 34.3291C922.312 34.3291 926.997 35.2569 931.357 37.1125C935.718 38.8753 939.475 41.3803 942.63 44.6275C945.877 47.7819 948.429 51.5394 950.284 55.9C952.14 60.2605 953.067 64.9458 953.067 69.9558V72.7392H886.128C886.407 76.8214 887.427 80.6717 889.19 84.29C891.045 87.8156 893.458 90.9236 896.427 93.6142C899.395 96.212 902.735 98.2994 906.447 99.8767C910.25 101.361 914.24 102.103 918.415 102.103C921.477 102.103 924.306 101.871 926.904 101.408C929.595 100.944 932.1 100.155 934.419 99.0417C936.739 97.9283 938.919 96.4903 940.96 94.7275C943.094 92.8719 945.181 90.5989 947.222 87.9083L951.258 91.2483ZM947.222 67.7292C947.222 63.9253 946.387 60.3533 944.717 57.0133C943.14 53.6733 940.96 50.7508 938.177 48.2458C935.486 45.648 932.332 43.6069 928.713 42.1225C925.095 40.638 921.291 39.8958 917.302 39.8958C913.312 39.8958 909.508 40.638 905.89 42.1225C902.364 43.5142 899.21 45.4625 896.427 47.9675C893.643 50.4725 891.37 53.4414 889.607 56.8742C887.845 60.2142 886.731 63.8325 886.267 67.7292H947.222Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
          <path d="M965.283 36.4167H977.947V51.8642C981.194 46.5758 985.508 42.4008 990.889 39.3391C996.363 36.1847 1002.39 34.6075 1008.98 34.6075C1014.18 34.6075 1019.05 35.628 1023.59 37.6691C1028.14 39.6175 1032.08 42.3544 1035.42 45.88L1031.39 49.7767C1028.51 46.8078 1025.17 44.4883 1021.37 42.8183C1017.56 41.0555 1013.43 40.1742 1008.98 40.1742C1004.71 40.1742 1000.68 41.0092 996.873 42.6792C993.162 44.2564 989.869 46.483 986.993 49.3592C984.209 52.1425 981.983 55.4361 980.313 59.24C978.735 62.9511 977.947 66.9406 977.947 71.2083V106H972.38V40.3133H965.283V36.4167Z" stroke="black" stroke-width="2" mask="url(#path-1-outside-1_18_2)" />
        </svg>


      </div>
    </div>
  )
}

export default Hero