export const SliderData = [
    {
        image: 'https://i.ibb.co/Qcmj6Z7/Screenshot-2022-12-20-at-4-44-01-PM.jpg'
    },
    {
        image: 'https://i.ibb.co/Ct1HXYk/Screenshot-2022-12-20-at-4-42-14-PM.jpg'
    },
    {
        image: 'https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1640&q=80'
    }
]